// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
@import "toastr";

#toast-container > div {
    opacity:1;
}

.alert-success{
    background-color: #00802f !important;
}

.alert-error{
    background-color: rgba(255, 0, 0, 1) !important;
}

@font-face {
    font-family: "Gill Sans Web";
    src: url("../fonts/gill-sans-regular-web.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Palatino Linotype Web";
    src: url("../fonts/palatino-linotype-regular-web.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

p, a, ul, li, div, body{
    font-family: 'Palatino Linotype Web';
}

strong{
    font-family: 'Palatino Linotype Web';
    font-weight: bold !important;
}

ul{
    font-size: 1.125rem;
    font-weight: 300;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Gill Sans Web';
}

table, th, td {
    border: 1px solid #00802f;
}

th, td{
    padding: 5px;
}

table {
    border-collapse: collapse;
}

table {
    width: 100%;
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// MASTER BILDER

.background-image-academic-programme{
    transition: all 2s;
    background-image: url('/images/webp/academic-programme.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-academic-programme.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-academic-programme.blur{
    background-image: url('/images/webp/academic-programme.webp');
    transition: all 2s;
}

.background-image-master-graduation01{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation01.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation01.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation01.blur{
    background-image: url('/images/webp/master-graduation01.webp');
    transition: all 2s;
}

.background-image-master-graduation02{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation02.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation02.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation02.blur{
    background-image: url('/images/webp/master-graduation02.webp');
    transition: all 2s;
}

.background-image-master-graduation03{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation03.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation03.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation03.blur{
    background-image: url('/images/webp/master-graduation03.webp');
    transition: all 2s;
}

.background-image-master-graduation04{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation04.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation04.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation04.blur{
    background-image: url('/images/webp/master-graduation04.webp');
    transition: all 2s;
}

.background-image-master-graduation05{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation05.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation05.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation05.blur{
    background-image: url('/images/webp/master-graduation05.webp');
    transition: all 2s;
}

.background-image-master-graduation06{
    transition: all 2s;
    background-image: url('/images/webp/master-graduation06.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-master-graduation06.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-master-graduation06.blur{
    background-image: url('/images/webp/master-graduation06.webp');
    transition: all 2s;
}

// PHD BILDER
.background-image-phd-graduation01{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation01.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation01.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation01.blur{
    background-image: url('/images/webp/phd-graduation01.webp');
    transition: all 2s;
}

.background-image-phd-graduation02{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation02.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation02.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation02.blur{
    background-image: url('/images/webp/phd-graduation02.webp');
    transition: all 2s;
}

.background-image-phd-graduation03{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation03.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation03.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation03.blur{
    background-image: url('/images/webp/phd-graduation03.webp');
    transition: all 2s;
}

.background-image-phd-graduation04{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation04.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation04.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation04.blur{
    background-image: url('/images/webp/phd-graduation04.webp');
    transition: all 2s;
}

.background-image-phd-graduation05{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation05.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation05.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation05.blur{
    background-image: url('/images/webp/phd-graduation05.webp');
    transition: all 2s;
}

.background-image-phd-graduation06{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation06.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation06.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation07.blur{
    background-image: url('/images/webp/phd-graduation07.webp');
    transition: all 2s;
}

.background-image-phd-graduation07{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation07.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation07.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation08.blur{
    background-image: url('/images/webp/phd-graduation08.webp');
    transition: all 2s;
}

.background-image-phd-graduation08{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation08.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation08.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation09.blur{
    background-image: url('/images/webp/phd-graduation09.webp');
    transition: all 2s;
}

.background-image-phd-graduation09{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation09.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation09.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation10.blur{
    background-image: url('/images/webp/phd-graduation10.webp');
    transition: all 2s;
}

.background-image-phd-graduation10{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation10.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation10.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation11.blur{
    background-image: url('/images/webp/phd-graduation11.webp');
    transition: all 2s;
}

.background-image-phd-graduation11{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation11.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation11.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation11.blur{
    background-image: url('/images/webp/phd-graduation11.webp');
    transition: all 2s;
}

.background-image-phd-graduation12{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation12.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation12.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation12.blur{
    background-image: url('/images/webp/phd-graduation12.webp');
    transition: all 2s;
}

.background-image-phd-graduation13{
    transition: all 2s;
    background-image: url('/images/webp/phd-graduation13.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-phd-graduation13.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-phd-graduation13.blur{
    background-image: url('/images/webp/phd-graduation13.webp');
    transition: all 2s;
}

.background-image-dies{
    transition: all 2s;
    background-image: url('/images/dies.jpg');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-dies.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-dies.blur{
    background-image: url('/images/dies.webp');
    transition: all 2s;
}

.background-image-fruehling{
    transition: all 2s;
    background-image: url('/images/webp/fruehling.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-fruehling.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-fruehling.blur{
    background-image: url('/images/webp/fruehling.webp');
    transition: all 2s;
}

.background-image-dezentral{
    transition: all 2s;
    background-image: url('/images/webp/dezentral.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-dezentral.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-dezentral.blur{
    background-image: url('/images/webp/dezentral.webp');
    transition: all 2s;
}

.background-image-stream{
    transition: all 2s;
    background-image: url('/images/webp/dies.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-stream{
    transition: all 2s;
    background-image: url('/images/webp/livestream.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-stream.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-stream.blur{
    background-image: url('/images/webp/livestream.webp');
    transition: all 2s;
}

.background-image-postkarte{
    transition: all 2s;
    background-image: url('/images/webp/postkarte.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-postkarte.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-postkarte.blur{
    background-image: url('/images/webp/postkarte.webp');
    transition: all 2s;
}

.background-image-olma{
    transition: all 2s;
    background-image: url('/images/webp/olma.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-olma.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-olma.blur{
    background-image: url('/images/webp/olma.webp');
    transition: all 2s;
}

.background-image-landing{
    transition: all 2s;
    background-image: url('/images/webp/hauptgebaeude.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-landing.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-landing.blur{
    background-image: url('/images/webp/hauptgebaeude-blur.webp');
    transition: all 2s;
}

.background-image-aula{
    transition: all 2s;
    background-image: url('/images/webp/aula.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-aula.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-aula.blur{
    background-image: url('/images/webp/aula-blur.webp');
    transition: all 2s;
}

.background-image-visitors{
    transition: all 2s;
    background-image: url('/images/webp/visitors.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-visitors.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-visitors.blur{
    background-image: url('/images/webp/visitors-blur.webp');
    transition: all 2s;
}

.background-image-welt{
    transition: all 2s;
    background-image: url('/images/webp/welt.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-welt.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-welt.blur{
    background-image: url('/images/webp/welt-blur.webp');
    transition: all 2s;
}

.background-image-pyramide{
    transition: all 2s;
    background-image: url('/images/webp/pyramide.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-pyramide.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-pyramide.blur{
    background-image: url('/images/webp/pyramide-blur.webp');
    transition: all 2s;
}

.background-image-museum{
    transition: all 2s;
    background-image: url('/images/webp/museum.webp');
    background-color: #0000007a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.background-image-museum.dimm{
    background-blend-mode: darken;
    transition: all 2s;
}

.background-image-museum.blur{
    background-image: url('/images/webp/museum-blur.webp');
    transition: all 2s;
}

.background-green{
    background-color: #00802f;
}

.navbar-light .navbar-toggler{
    border: 0px;
}

.current-counter{
    padding: 6px 10px;
    display: inline-block;
    background: #f4f4f4;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    line-height: 1.6;
    border: 1px solid #00802f;
    vertical-align: middle;
    color: #000;
}

.count-click{
    display: inline-block;
}

.scroll-section{
    min-height: 100vh;
}

@media screen and (max-width: 1200px){
    .scroll-section{
        min-height: 110vh;
    }
}

.bg-white-transparent{
    background-color: #ffffffe0;
    transition: all 2s;
}

.bg-dark-transparent{
    background-color: #4f4f4fe0;
    transition: all 2s;
}

#timer{
    display: inline-block;
    font-weight: 100;
    text-align: center;
    font-size: 30px;
}

#timer > div{
    padding: 10px;
    border-radius: 3px;
    display: inline-block;
}

#timer div > span{
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
}

.smalltext{
    padding-top: 5px;
    font-size: 16px;
}

.youtube-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 5px;
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
}

.youtube-player img {
    object-fit: cover;
    display: block;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
}

.youtube-player img:hover {
    -webkit-filter: brightness(75%);
}

.youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url('/images/play.png') no-repeat;
    cursor: pointer;
}

.rahmen{
    background: url('/images/rahmen.png') no-repeat;
    background-size: contain;
}

.world-map path{
    stroke:#00802f;
    stroke-width: 2px;
}

.top-left{
    text-align: left;
}

.top-left svg{
    transform: rotate(135deg);
}

.top-right{
    text-align: right;
}

.top-right svg{
    transform: rotate(225deg);
}

.bottom-left{
    text-align: left;
}

.bottom-left svg{
    transform: rotate(45deg);
}

.bottom-right{
    text-align: right;
}

.bottom-right svg{
    transform: rotate(315deg);
}

.round-bordered-icon{
    display: inline-block;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: #fff;
}

.round-bordered-icon-b{
    display: inline-block;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: #000;
}

input{
    font-size: 14px !important;
}

.svg-map{
    max-height: 500px;
}

.scrollable{
    overflow-y: scroll;
    height: 500px;
}


input[type=range] {
    width: 100%;
    margin: 7.8px 0;
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 0.25rem;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
    margin-top: -8px;
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 0.25rem;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #009a38;
}
input[type=range]::-moz-range-track {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
    border-radius: 0.25rem;
}
input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 8.8px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-ms-fill-lower {
    background: #006726;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 2.6px;
}
input[type=range]::-ms-fill-upper {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 2.6px;
}
input[type=range]::-ms-thumb {
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
    margin-top: 0px;
    border-radius: 0.25rem;
}
input[type=range]:focus::-ms-fill-lower {
    background: #00802f;
}
input[type=range]:focus::-ms-fill-upper {
    background: #009a38;
}

@supports (-ms-ime-align:auto) {
    input[type=range] {
        margin: 0;
    }
}


input[type=range] {
    width: 100%;
    margin: 7.8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
    margin-top: -8px;
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #009a38;
}
input[type=range]::-moz-range-track {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 8.8px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}
input[type=range]::-ms-fill-lower {
    background: #006726;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 2.6px;
}
input[type=range]::-ms-fill-upper {
    background: #00802f;
    border: 0.2px solid rgba(255, 255, 255, 0.9);
    border-radius: 2.6px;
}
input[type=range]::-ms-thumb {
    width: 24px;
    height: 24px;
    background: #00802f;
    border: 2px solid #ffffff;
    cursor: pointer;
    margin-top: 0px;
}
input[type=range]:focus::-ms-fill-lower {
    background: #00802f;
}
input[type=range]:focus::-ms-fill-upper {
    background: #009a38;
}

@supports (-ms-ime-align:auto) {
    input[type=range] {
        margin: 0;
    }
}
  
.force.hide{
    display: none !important;
}

.force-timer.hide{
    display: none !important;
}

.cookie-consent{
    background-color: #fff;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    color: #000;
    font-size: 1.125rem;
    font-weight: 300;
    margin: auto;
    text-align: center;
}

button.js-cookie-consent-agree.cookie-consent__agree{
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    background-color: #00802f;
    border-color: #00802f;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.6;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
   }
    100% {
        transform: translateY(0);
        opacity: 1;
   }
}

.video iframe {
    max-width: 100%;
    max-height: 100%;
}

// @media screen and (min-width: 1000px){
//     .video.stuck {
//         position: fixed !important;
//         top: 0px;
//         left: 0px;
//         width: 440px !important;
//         height: 250px !important;
//         transform: translateY(100%);
//         animation: fade-in-up 0.75s ease forwards !important;
//         z-index: 999;
//         padding-bottom: 0px !important;
//         border: 3px solid #fff;
//     }
// }

.timestamps{
    height: 330px;
    overflow-y: scroll;
}

.timestamp:hover{
    background-color: rgba(0, 128, 47, 0.5) !important;
    color: #fff !important;
}

.timestamp.active{
    background-color: #00802f !important;
    color: #fff !important;
}

.timestamp h4{
    margin-top: 0.5rem;
}

.open-popup:hover{
    text-decoration: underline;
    transition: all 0.5s;
    cursor: pointer;
}

.video-self{
    position: absolute;
    z-index: -1;
}

.people{
    width: 100%; 
    height: auto;
    margin-bottom: 35px;
}

.postkarte{
    width: 100%; 
    height: auto; 
    transition: all 0.5s;
}

.postkarten-holder{
    transition: all 0.5s;
}

.postkarten-holder:hover{
    transform: scale(1.05);
}

.modal-content{
    border-radius: 0px;
}

@media (max-width: 768px) {
    .full-w-sm{
        width: 100% !important;
    }
}

.clap-icon svg{
    fill: #fff;
}

.clap-svg{
    fill: #fff;
    width: 20px;
    height: 20px;
}

.always-white path{
    fill: #fff !important
}

.feier-column{
    cursor: pointer;
}

.slide-inner {
    height: 100%;
    position: relative;
}

.slide-inner a{
    position: absolute;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.slider .slick-dots li button{
    width: 30px !important;
    height: 30px !important;
}

.slider .slick-dots li.slick-active button:before{
    color: #fff !important;
    opacity: 1 !important;
    width: 30px !important;
    height: 30px !important;
}

.slider .slick-dots li button:before{
    color: #fff !important;
    opacity: 0.75 !important;
    width: 30px !important;
    height: 30px !important;  
}

.slider .slide-arrow{
	background: transparent;
  position: absolute;
  top: 46%;
  margin-top: -15px;
}
.slider .prev-arrow{
	background: #fff;
  left: -10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #00802f;
	z-index: 999;
	text-align: center !important
}
.slider .next-arrow{
	background: #fff;
  right: -10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #00802f;
	z-index: 999;
	text-align: center !important
}

.slider .next-arrow:hover, .slider .prev-arrow:hover{
	background: #00802f;
}

.slider .next-arrow:hover i, .slider .prev-arrow:hover i{
	color: #ffffff;
}

.slider .next-arrow i, .slider .prev-arrow i{
	color: #00802f;
	vertical-align: middle;
}

#pdf-canvas{
    width: 100% !important;
    margin-top: 30px;
    margin-bottom: 30px;
}

.paginator{
    background: #fff !important;
    border-color: #fff !important;
    border: 1px solid transparent !important;
    padding: 0.7rem 0.75rem !important;
    font-size: 0.9rem !important;
    line-height: 1.6 !important;
    border-radius: 0.25rem !important;
    text-align: center !important;
    vertical-align: middle !important;
    margin-left: 10px;
}

.kickoff-highlighted{
    background-color: rgba(0, 128, 47, 0.8);
}

/* Flipbook */
.flipbook-wrapper {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 5%;
  width: 90%;
}

.flipbook-aspect {
  padding-bottom: 70%;
  position: relative;
  width: 100%;
}

.flipbook-aspect-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.flipbook {
  height: 100%;
  transition: margin-left 0.25s ease-out;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flipbook .flipbook-page {
  height: 100%;
  width: 100%;
}

.flipbook .flipbook-page img {
  max-width: 100%;
  height: 100%;
}

@media screen and (max-width: 1199px){
    .firework-headline {
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px){
    .firework-headline {
        font-size: 80px;
    }
}